/*
 * @Author: CL
 * @Date: 2021-06-29 14:17:19
 * @LastEditTime: 2021-06-29 15:02:33
 * @Description: 评论相关的api
 */

import request from './request';

/**
 * 分页查询评论
 * @param {*} page 
 * @param {*} size 
 * @returns 
 */
export function getComments(page, size, data){
  return request({
    url: `/api/comments/${page}/${size}`,
    method: 'get',
    params: data
  })
}

/**
 * 添加评论
 * @param {*} data 
 * @returns 
 */
export function addComment(data) {
  return request({
    url: '/api/comments',
    method: 'post',
    data
  })
}
