<!--
 * @Author: CL
 * @Date: 2021-06-28 22:39:54
 * @LastEditTime: 2021-07-05 11:01:33
 * @Description: 文章详情页
-->

<template>
  <div class="detail-contain" ref="detail" @scroll="handleScroll">
    <TopProgress :progress="progress" />
    <div>
      <BlogDetail />
    </div>
    <ToTop :isShow="isShow" />
    <GoHome />
  </div>
</template>

<script>
import BlogDetail from './components/BlogDetail.vue';
import ToTop from '@/components/ToTop';
import TopProgress from '@/components/TopProgress';
import GoHome from '@/components/GoHome';


export default {
  data(){
    return {
      isShow: false,

      progress: 0
    }
  },

  methods: {
    handleScroll(){
      if(this.$refs.detail.scrollTop == 0){
        this.isShow = false;
      }else if(this.$refs.detail.scrollTop > 0){
        this.isShow = true;
      }

      if(this.$refs.detail.scrollTop + this.$refs.detail.clientHeight >= this.$refs.detail.scrollHeight - 50){
        this.$bus.$emit('fetchMore');
      }

      //计算上边的进度条
      this.setProgress();
    },

    setProgress(){
      let timer = null;
      clearTimeout(timer);
      timer = setTimeout(() => {
        const all = this.$refs.detail.scrollHeight;
        if(this.$refs.detail.scrollTop == 0){
          this.progress = 0;
        }else{
          this.progress = parseFloat((this.$refs.detail.scrollTop + this.$refs.detail.clientHeight) / all);
        }
      }, 1000)
    },

    handleScrollTop(){
      this.$refs.detail.scrollTop = 0;
    }
  },

  components: {
    BlogDetail,
    ToTop,
    TopProgress,
    GoHome,
  },

  mounted(){
    this.$bus.$on('scrollTop', this.handleScrollTop);
  },

  destroyed() {
    this.$bus.$off('scrollTop', this.handleScrollTop);
  },
}
</script>

<style lang="less" scoped>
.detail-contain{
  height: 100%;
  overflow-y: scroll;
  padding: 30px 300px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
</style>
