<!--
 * @Author: CL
 * @Date: 2021-06-29 14:03:21
 * @LastEditTime: 2021-06-30 10:37:38
 * @Description: 博客评论
-->

<template>
  <div class="blogcomment-contain">
    <Comments @submit="handleSubmit" />
    <CommentList title="评论列表" :subTitle="total" :list="list"/>
    <LoadingMore :isShow="isShow" :bottom="30" />
    <NoMore :isShow="hasMore" word="评论" />
  </div>
</template>

<script>
import Comments from '@/components/Comments';
import CommentList from './CommentList.vue';
import { getComments } from '@/api/comments';
import LoadingMore from '@/components/LoadingMore';
import { addComment } from '@/api/comments';
import NoMore from '@/components/NoMore';

export default {
  data(){
    return {
      page: 1,
      size: 5,
      total: 0,
      list: [],
      isShow: false,
      hasMore: false
    }
  },

  methods: {
    async queryComments(){
      try{
        const { page, size } = this;
        const { id } = this.$route.params;
        const res = await getComments(page, size, { blogId: id });
        if(res.code == 200){
          const { count, rows } = res.data;
          this.total = count;
          this.list = rows;
        }
      }catch(err){
        console.log(err);
      }
    },

    async fetchMore(){
      if(this.total == this.list.length){
        this.hasMore = true;
        return
      };
      this.isShow = true;
      this.page++;
      const res = await getComments(this.page, this.size, { blogId: this.$route.params.id });
      await this.$utils.delay(1500);
      this.isShow = false;
      if(res.code == 200){
        this.list = [...this.list, ...res.data.rows];
      }
    },

    async handleSubmit(data = {}, callback){
      try{
        const temp = {
          ...data,
          BlogId: +this.$route.params.id,
          parentId: -1
        }
        const res = await addComment(temp);
        if(res.code == 200){
          this.list.unshift(res.data);
          this.total++;
          callback('评论成功');
        }
      }catch(err){
        console.log(err);
      }
    }
  },

  created(){
    this.queryComments();
  },

  mounted(){
    this.$bus.$on('fetchMore', this.$utils.deBounce(this.fetchMore, 500));
  },

  destroyed() {
    this.$bus.$off('fetchMore', this.$utils.deBounce(this.fetchMore, 500));
  },

  components: {
    Comments,
    CommentList,
    LoadingMore,
    NoMore
  }
}
</script>

<style lang="less" scoped>

</style>
