<!--
 * @Author: CL
 * @Date: 2021-06-28 23:25:45
 * @LastEditTime: 2021-08-03 23:43:36
 * @Description: 文章详情组件
-->

<template>
  <div class="blogdetail-contain" v-loading="loading">
    <h1>{{ info.title }}</h1>
    <div class="labels">
      <!-- <BlogLabels :info="info" /> -->
      <div>{{ info.createTime }}</div>
      <div>浏览：{{ info.views }}</div>
      <div>喜欢：{{ info.likes }}</div>
      <div>评论：{{ info.comments }}</div>
      <div>标签：{{ info.tags }}</div>
    </div>
    <div v-html="info.content" class="markdown-body"></div>

    <div class="comment" v-if="!loading" >
      <BlogComment />
    </div>
  </div>
</template>

<script>
import marked from 'marked'
import { getBlogDetail } from '@/api/blog';
import '@/styles/markdown.css';   //给markdown加样式的
import "highlight.js/styles/github.css";   //给代码加高亮的
import BlogLabels from '../../Article/components/BlogLabels.vue';
import BlogComment from './BlogComment.vue';

export default {
  data(){
    return {
      loading: true,
      info: {}
    }
  },

  methods: {
    async queryDetail(id){
      this.loading = true;
      try{
        const res = await getBlogDetail(id);
        this.loading = false
        if(res.code == 200){
          this.info = {
            ...res.data,
            content: marked(res.data.content),
            comments: res.data.Comments? res.data.Comments.length : 0
          };
          this.$utils.setRouterTitle(this.info.title);
        }
      }catch(err){
        console.log(err);
      }
    }
  },

  created(){
    const { id } = this.$route.params;
    this.queryDetail(id);
  },

  components: {
    BlogLabels,
    BlogComment
  },

  watch: {
    //监听路由的变化，防止在地址栏处就修改详情id
    $route: {
      handler(val){
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.blogdetail-contain{
  .labels{
    margin: 30px 0 50px 0;
    display: flex;
    align-items: center;
    font-size: 14px;

    > div{
      margin: 0 10px;

      &:nth-of-type(1){
        color: @success;
        margin-left: 0;
      }

      &:nth-of-type(2){
        color: @warning;
      }

      &:nth-of-type(3){
        color: @primary;
      }

      &:nth-of-type(4){
        color: @info;
      }

      &:nth-of-type(5){
        color: @danger;
      }
    }
  }

  .comment{
    margin-top: 30px;
  }
}
</style>
