<!--
 * @Author: CL
 * @Date: 2021-06-29 09:10:18
 * @LastEditTime: 2021-06-29 16:02:12
 * @Description: 上边进度条组件
 *
 * 1. progress: Number  进度条的进度值
-->

<template>
  <div class="topprogress-contain" :style="{ width: getProgress + '%' }">

  </div>
</template>

<script>
export default {
  props: {
    //给我传一个进度条的进度值
    progress: {
      type: Number,
      required: true
    }
  },

  computed: {
    getProgress(){
      if(this.progress < 0.001) return 0;
      if(this.progress > 99.99) return 100;
      return (this.progress * 100).toFixed(2);
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.topprogress-contain{
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background: @primary;
  transition: all .5s;
  z-index: 999;
}
</style>
